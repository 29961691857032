import React from 'react';
import { NavLink } from 'react-router-dom';

import navigation from '../menu-items';
import './common.css';

export default function LeftSideBar() {
  return (
    <nav className="pcoded-navbar">
      <div className="sideNavBar">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            borderBottom: '3px solid #5BC5D0',
          }}
        ></div>
        {navigation.items[0].children.map((item, index) => {
          return (
            <div key={index + '-main'}>
              {item.url === undefined ? (
                <div className="mainMenu">
                  <div className={'menuNo'}>{parseInt(index) + 1}</div>
                  <div className="menuTitle">
                    <p>{item.title}</p>
                  </div>
                </div>
              ) : (
                <NavLink to={item.url} style={{ textDecoration: 'none' }}>
                  <div className="mainMenu">
                    <div className={'menuNo'}>{parseInt(index) + 1}</div>
                    <div className="menuTitle">
                      <p>{item.title}</p>
                    </div>
                  </div>
                </NavLink>
              )}
              {item.children !== undefined ? (
                <div className="subMenuSection">
                  {item.children.map((subitem, subindex) => {
                    return (
                      <div key={subindex + '-submain'}>
                        <NavLink
                          to={subitem.url}
                          style={{ textDecoration: 'none' }}
                        >
                          <div
                            className={
                              subitem.id === 'logout' ? 'redMenu' : 'subMenu'
                            }
                          >
                            <p>{subitem.title}</p>
                          </div>
                        </NavLink>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    </nav>
  );
}
