import {defaultThemes} from 'react-data-table-component'
const customStyles = {
    header: {
        style: {
            minHeight: '48px',
        },
    },
    headRow: {
        style: {
            borderTopStyle: 'solid',
            borderTopWidth: '1px',
            borderTopColor: defaultThemes.default.divider.default,
            minHeight: '48px',
        },
    },
    headCells: {
        style: {
            // '&:not(:last-of-type)': {
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                borderRightColor: defaultThemes.default.divider.default,
                backgroundColor:"#1f96d3",
                color:"#ffffff"
            // },
        },
    },
    cells: {
        style: {
            borderRightStyle: 'solid',
            borderRightWidth: '1px',
            borderRightColor: defaultThemes.default.divider.default,
            ':first-of-type': {
                borderLeftStyle: 'solid',
                borderLeftWidth: '1px',
                borderLeftColor: defaultThemes.default.divider.default,
            }
        },
    },
};

export default customStyles;