import React, {useEffect, useState} from 'react';
import './common.css';
import {Col, Row} from 'react-bootstrap';
import API from './API';
function Dashboard() {

  const[data, setData] = useState([])


  const fetchCases = async () => {
    try {
      setData(await API.fetchDashboard());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(()=>{
    fetchCases();
  }, [])

  return(
    <div>
      {data.length === undefined ? 
      <Row>
        <Col>
          <Display name="Commentaries" value={data?.Commentaries[0]?.expertcnt}/>
        </Col>
        <Col>
          <Display name="Courses" value={data?.Courses[0]?.coursecnt}/>
        </Col>
        <Col>
          <Display name="Institutions" value={data?.Institutions[0]?.instcnt}/>
        </Col>
        <Col>
          <Display name="Medical" value={data?.Medical[0]?.medicalcnt}/>
        </Col>
        <Col>
          <Display name="News" value={data?.News[0]?.newscnt}/>
        </Col>
      </Row> : null }
    </div>
  )
}
export default Dashboard;

const Display = (props) =>{
  return(
    <div className="displayCard">
      <div className="displayCardBody">
        {props.value}
      </div>
      <div className="displayCardFooter">
        {props.name}
      </div>
    </div>
  )
}
