import React from 'react';
// import ReactDOM from 'react-dom';
// import { createStore, applyMiddleware } from 'redux';
// import { Provider } from 'react-redux';
// import { BrowserRouter } from 'react-router-dom';

import App from './App';
// import * as serviceWorker from './serviceWorker';
// import reducer from './store/reducer';
// import config from './config';
// import { PersistGate } from 'redux-persist/lib/integration/react';
// import { persistStore, persistReducer } from 'redux-persist';
// import { composeWithDevTools } from 'redux-devtools-extension';
// import thunk from 'redux-thunk';
// import storage from 'redux-persist/lib/storage';

// const persistConfig = {
//   key: 'root',
//   storage,
// };

// const persistedReducer = persistReducer(persistConfig, reducer);

// const store = createStore(
//   persistedReducer,
//   composeWithDevTools(applyMiddleware(thunk))
// );
// const persistedStore = persistStore(store);

const app = () => {
  return (
    // <Provider store={store}>
    //   <PersistGate loading={null} persistor={persistedStore}>
    //     <BrowserRouter basename={config.basename}>
          <App />
    //     </BrowserRouter>
    //   </PersistGate>
    // </Provider>
  );
};

export default app;
// ReactDOM.render(app, document.getElementById('root'));

// serviceWorker.unregister();
