import React, { useState } from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
import "./Home.css";
// import demo from "../assets/img/demo.png";
import { fileUpload } from "./fileUploads";
import axios from 'axios';
import { site_ip } from '../config';
import { useNavigate } from "react-router-dom";
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
const Home = () => {
  const navigate = useNavigate();
  const [timestamp, setTimeStamp] = useState(Date.now())
  const blankValues = {
    salutation: "",
    full_name: "",
    user_profile_img: "",
    api_membership_no: "",
    date_of_birth: "",
    date_of_joining: "",
    user_email: "",
    user_mobile: "",
    user_phone: "",
    // state_id:req.body.state_id,
    // country_id:req.body.country_id,
    residence_address: "",
    office_address: "",
    nos_of_publications: "",
    nos_of_chapters_in_books: "",
    editorship_of_national_state_level: "",
    publication_attachment: "",
    oration_title: "",
    oration_org: "",
    oration_year: "",
    oration_proof: "",
    award_title: "",
    award_org: "",
    award_year: "",
    award_proof: "",
    research_work: "",
    letter_of_sanction: "",
    departmental_research: "",
    title_page_abstract: "",
    cont_api_post_held: "",
    cont_api_organization: "",
    cont_api_level: "",
    cont_api_year: "",
    cont_api_proof: "",
    cme_participation_speaker: "",
    cme_title_talk: "",
    cme_meeting_name: "",
    cme_year: "",
    // social_welfare_service: "",
    // social_welfare_evidence: []
  }
  const [uploading, setUploading] = useState(false)
  const [attachments, setAttachments] = useState({
    user_profile_img: null,
    publication_attachment: null,
    oration_proof: null,
    award_proof: null,
    letter_of_sanction: null,
    title_page_abstract: null,
    cont_api_proof: null
  })

  const handleAttachment = (name, file) => {
    // Check file size
    if ((file.size / 1024) > 2048) {
      alert("Miximum file size is 2MB")
      return
    }
    if (name === "user_profile_img") {
      let dp_url = URL.createObjectURL(file)
      setDP(dp_url)
    }
    setAttachments({ ...attachments, [name]: file })
  };


  // Files 
  const [dp, setDP] = useState(null)
  const [values, setValues] = useState(blankValues)
  const handleChange = (e) => {
    setRequiredClasses(blankClasses)
    let { name, value } = e.target;
    setValues({ ...values, [name]: value });
  }

  let blankPQ = {
    degree_name: "",
    year_of_passing: "",
    institute: "",
    university: "",
    certificate_img: null
  }

  const [professionalQualifications, setProfessionalQualifications] = useState([blankPQ, blankPQ])

  const handlePQ = (type, index) => {
    if (type === "Add") {
      let current_list = [...professionalQualifications];
      current_list.push(blankPQ)
      setProfessionalQualifications(current_list)
    } else {
      let current_list = [...professionalQualifications];
      current_list.splice(index, 1);
      setProfessionalQualifications(current_list)
    }
  }

  const handlePQAttachment = (index, attachment) => {
    if ((attachment.size / 1024) > 2048) {
      alert("Miximum file size is 2MB")
      return
    }
    let qualification_list = [...professionalQualifications];
    let values = { ...qualification_list[index] }
    values = { ...values, ["certificate_img"]: attachment };
    qualification_list[index] = values;
    setProfessionalQualifications(qualification_list)
  }


  const handleQualification = (index, e) => {
    setRequiredClasses(blankClasses)
    let qualification_list = [...professionalQualifications];
    let values = { ...qualification_list[index] }
    let { name, value } = e.target;
    values = { ...values, [name]: value };
    qualification_list[index] = values;
    setProfessionalQualifications(qualification_list)
  }

  let blankExperience = {
    hospital_name: "",
    number_of_beds: "",
    served_from_year: "",
    served_to_year: "",
    post_held: ""
  }

  const [experienceList, setExperienceList] = useState([blankExperience])

  const handleExperience = (type, index) => {
    if (type === "Add") {
      let current_list = [...experienceList];
      current_list.push(blankExperience)
      setExperienceList(current_list)
    } else {
      let current_list = [...experienceList];
      current_list.splice(index, 1);
      setExperienceList(current_list)
    }
  }

  const handleExperienceValues = (index, e) => {
    setRequiredClasses(blankClasses)
    let experience_list = [...experienceList];
    let values = { ...experience_list[index] }
    let { name, value } = e.target;
    values = { ...values, [name]: value };
    experience_list[index] = values;
    setExperienceList(experience_list)
  }

  // Publication Attachment List
  let blank_publication_attachment = null;
  const [publicationAttachmentList, setPublicationAttachmentList] = useState([blank_publication_attachment])

  const handlePublicationAttachment = (type, index) => {
    if (type === "Add") {
      let current_list = [...publicationAttachmentList];
      current_list.push(blank_publication_attachment)
      setPublicationAttachmentList(current_list)
    } else {
      let current_list = [...publicationAttachmentList];
      current_list.splice(index, 1);
      setPublicationAttachmentList(current_list)
    }
  }

  const managePublicationAttachment = (index, e, type) => {
    let current_list = [...publicationAttachmentList];
    if (type === "file") {
      if ((e.size / 1024) > 2048) {
        alert("Miximum file size is 2MB")
        return
      }
      current_list[index] = e;
      setPublicationAttachmentList(current_list)
    }
  }



  // Letter of Sanction List
  let blank_sanction_attachment = null;
  const [sanctionAttachmentList, setSanctionAttachmentList] = useState([blank_sanction_attachment])

  const handleSanctionAttachment = (type, index) => {
    if (type === "Add") {
      let current_list = [...sanctionAttachmentList];
      current_list.push(blank_sanction_attachment)
      setSanctionAttachmentList(current_list)
    } else {
      let current_list = [...sanctionAttachmentList];
      current_list.splice(index, 1);
      setSanctionAttachmentList(current_list)
    }
  }

  const manageSanctionAttachment = (index, e, type) => {
    let current_list = [...sanctionAttachmentList];
    if (type === "file") {
      if ((e.size / 1024) > 2048) {
        alert("Miximum file size is 2MB")
        return
      }
      current_list[index] = e;
      setSanctionAttachmentList(current_list)
    }
  }

  // Title Page Abstract List
  let blank_abstract_attachment = null;
  const [abstractAttachmentList, setAbstractAttachmentList] = useState([blank_abstract_attachment])

  const handleAbstractAttachment = (type, index) => {
    if (type === "Add") {
      let current_list = [...abstractAttachmentList];
      current_list.push(blank_abstract_attachment)
      setAbstractAttachmentList(current_list)
    } else {
      let current_list = [...abstractAttachmentList];
      current_list.splice(index, 1);
      setAbstractAttachmentList(current_list)
    }
  }

  const manageAbstractAttachment = (index, e, type) => {
    let current_list = [...abstractAttachmentList];
    if (type === "file") {
      if ((e.size / 1024) > 2048) {
        alert("Miximum file size is 2MB")
        return
      }
      current_list[index] = e;
      setAbstractAttachmentList(current_list)
    }
  }

  // Oration handling
  let blankOration = {
    oration_title: "",
    oration_org: "",
    oration_year: "",
    oration_proof: null
  }

  const [orationList, setOrationList] = useState([blankOration])

  const handleOration = (type, index) => {
    if (type === "Add") {
      let current_list = [...orationList];
      current_list.push(blankOration)
      setOrationList(current_list)
    } else {
      let current_list = [...orationList];
      current_list.splice(index, 1);
      setOrationList(current_list)
    }
  }

  const handleOrationValues = (index, e, type) => {
    console.log(e)
    let oration_list = [...orationList];
    let values = { ...oration_list[index] }
    if (type === "file") {
      if ((e.size / 1024) > 2048) {
        alert("Miximum file size is 2MB")
        return
      }
      values = { ...values, oration_proof: e };
      oration_list[index] = values;
      setOrationList(oration_list)
    } else {
      let { name, value } = e.target;
      values = { ...values, [name]: value };
      oration_list[index] = values;
      setOrationList(oration_list)
    }
  }

  // Award handling
  let blankAward = {
    award_title: "",
    award_org: "",
    award_year: "",
    award_proof: null
  }

  const [awardList, setAwardList] = useState([blankAward])

  const handleAward = (type, index) => {
    if (type === "Add") {
      let current_list = [...awardList];
      current_list.push(blankAward)
      setAwardList(current_list)
    } else {
      let current_list = [...awardList];
      current_list.splice(index, 1);
      setAwardList(current_list)
    }
  }

  const handleAwardValues = (index, e, type) => {
    let award_list = [...awardList];
    let values = { ...award_list[index] }
    if (type === "file") {
      if ((e.size / 1024) > 2048) {
        alert("Miximum file size is 2MB")
        return
      }
      values = { ...values, award_proof: e };
      award_list[index] = values;
      setAwardList(award_list)
    } else {
      let { name, value } = e.target;
      values = { ...values, [name]: value };
      award_list[index] = values;
      setAwardList(award_list)
    }
  }


  // Contribution to API handling
  let blankContribution = {
    cont_api_post_held: "",
    cont_api_organization: "",
    cont_api_level: "",
    cont_api_year: "",
    cont_api_proof: null
  }

  const [contributionList, setContributionList] = useState([blankContribution])

  const handleContribution = (type, index) => {
    if (type === "Add") {
      let current_list = [...contributionList];
      current_list.push(blankContribution)
      setContributionList(current_list)
    } else {
      let current_list = [...contributionList];
      current_list.splice(index, 1);
      setContributionList(current_list)
    }
  }

  const handleContributionValues = (index, e, type) => {
    let contribution_list = [...contributionList];
    let values = { ...contribution_list[index] }
    if (type === "file") {
      if ((e.size / 1024) > 2048) {
        alert("Miximum file size is 2MB")
        return
      }
      values = { ...values, cont_api_proof: e };
      contribution_list[index] = values;
      setContributionList(contribution_list)
    } else {
      let { name, value } = e.target;
      values = { ...values, [name]: value };
      contribution_list[index] = values;
      setContributionList(contribution_list)
    }
  }

  // Contribution to API handling
  let blankcme = {
    cme_participation_speaker: "",
    cme_title_talk: "",
    cme_meeting_name: "",
    cme_year: ""
  }

  const [cmeList, setCmeList] = useState([blankcme])

  const handleCme = (type, index) => {
    if (type === "Add") {
      let current_list = [...cmeList];
      current_list.push(blankcme)
      setCmeList(current_list)
    } else {
      let current_list = [...cmeList];
      current_list.splice(index, 1);
      setCmeList(current_list)
    }
  }

  const handleCmeValues = (index, e, type) => {
    let cme_list = [...cmeList];
    let values = { ...cme_list[index] }
    if (type === "file") {
      if ((e.size / 1024) > 2048) {
        alert("Miximum file size is 2MB")
        return
      }
      values = { ...values, cont_api_proof: e };
      cme_list[index] = values;
      setCmeList(cme_list)
    } else {
      let { name, value } = e.target;
      values = { ...values, [name]: value };
      cme_list[index] = values;
      setCmeList(cme_list)
    }
  }

  // Evidence Handling
  let blankEvidence = {
    social_welfare_service: "",
    social_welfare_evidence: null,
  }

  const [evidenceList, setEvidenceList] = useState([blankEvidence])

  const handleEvidence = (type, index) => {
    if (type === "Add") {
      let current_list = [...evidenceList];
      current_list.push(blankEvidence)
      setEvidenceList(current_list)
    } else {
      let current_list = [...evidenceList];
      current_list.splice(index, 1);
      setEvidenceList(current_list)
    }
  }

  const handleEvidenceValues = (index, e, type) => {
    let evidence_list = [...evidenceList];
    let values = { ...evidence_list[index] }
    if (type === "file") {
      if ((e.size / 1024) > 2048) {
        alert("Miximum file size is 2MB")
        return
      }
      values = { ...values, social_welfare_evidence: e };
      evidence_list[index] = values;
      setEvidenceList(evidence_list)
    } else {
      let { name, value } = e.target;
      values = { ...values, [name]: value };
      evidence_list[index] = values;
      setEvidenceList(evidence_list)
    }

  }

  let blankProposer = {
    proposer_name: "",
    membership_no: "",
    proposer_email: "",
    proposer_phone: ""
  }

  const [proposerList, setProposerList] = useState([blankProposer, blankProposer])

  const handleProposerValues = (index, e) => {
    setRequiredClasses(blankClasses)
    let proposer_list = [...proposerList];
    let values = { ...proposer_list[index] }
    let { name, value } = e.target;
    values = { ...values, [name]: value };
    proposer_list[index] = values;
    setProposerList(proposer_list)
  }

  const validateDegree = (items) => {
    let status = true;
    if (items[0]?.degree_name?.trim() === "" ||
      items[0]?.year_of_passing?.trim() === "" ||
      items[0]?.institute?.trim() === "" ||
      items[0]?.university?.trim() === "" ||
      items[0]?.certificate_img === null) {
      status = false
    }
    return status
  }

  const validateExperience = (items) => {
    let status = true;
    if (items[0]?.hospital_name?.trim() === "" ||
      items[0]?.number_of_beds?.trim() === "" ||
      items[0]?.served_from_year?.trim() === "" ||
      items[0]?.served_to_year?.trim() === "" ||
      items[0]?.post_held?.trim() === "") {
      status = false
    }
    return status
  }

  const validateProposer = (items, index) => {
    let status = true;
    if (items?.proposer_name?.trim() === "" ||
      items?.membership_no?.trim() === "" ||
      !validateEmail(items?.proposer_email?.trim()) ||
      (items?.proposer_email?.trim() === values?.user_email.trim()) ||
      (items?.proposer_email?.trim() === proposerList[index].proposer_email?.trim()) ||
      !phonenumber(items?.proposer_phone?.trim())) {
      status = false;
    } return status
  }

  const blankClasses = {
    full_name: "form-input",
    api_membership_no: "form-input",
    date_of_joining: "form-input",
    date_of_birth: "form-input",
    residence_address: "form-input",
    office_address: "form-input",
    user_mobile: "form-input",
    user_email: "form-input",
    qualification: "form-input",
    experience: "form-input",
    nos_of_publications: "form-input",
    nos_of_chapters_in_books: "form-input",
    editorship_of_national_state_level: "form-input",
    publication_attachment: "form-input",
    research_work: "form-input",
    letter_of_sanction: "form-input",
    departmental_research: "form-input",
    title_page_abstract: "form-input",
    proposer1: "form-input",
    proposer2: "form-input"
  }
  const [requiredClasses, setRequiredClasses] = useState(blankClasses)

  const previewForm = () => {
    localStorage.setItem("preview", JSON.stringify({
      state: {
        values: values,
        professionalQualifications: professionalQualifications,
        experienceList: experienceList,
        orationList: orationList,
        awardList: awardList,
        contributionList: contributionList,
        cmeList: cmeList,
        evidenceList: evidenceList,
        proposerList: proposerList,
        attachments: attachments,
        dp: dp
      }
    }))
    window.open("/fellowship/preview", '_blank');
  }

  const validateEmail = (mail) => {
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  };

  const phonenumber = (mobile) => {
    var phoneno = /^\d{10}$/;
    if (String(mobile).match(phoneno)) {
      return true;
    } else {
      return false;
    }
  };

  function blobToFile(theBlob, fileName) {
    var file = new File([theBlob], fileName);
    return file
  }

  const generatePDF = async () => {
      const divToPrint = document.querySelector('#preview');
      const canvas = await html2canvas(divToPrint, { useCORS: true, allowTaint: true, scrollY: 0 });
      const image = { type: 'jpeg', quality: 0.98 };
      const margin = [0.5, 0.5];
      const filename = 'myfile.pdf';

      var imgWidth = 8.5;
      var pageHeight = 11;

      var innerPageWidth = imgWidth - margin[0] * 2;
      var innerPageHeight = pageHeight - margin[1] * 2;

      // Calculate the number of pages.
      var pxFullHeight = canvas.height;
      var pxPageHeight = Math.floor(canvas.width * (pageHeight / imgWidth));
      var nPages = Math.ceil(pxFullHeight / pxPageHeight);

      // Define pageHeight separately so it can be trimmed on the final page.
      var pageHeight = innerPageHeight;

      // Create a one-page canvas to split up the full image.
      var pageCanvas = document.createElement('canvas');
      var pageCtx = pageCanvas.getContext('2d');
      pageCanvas.width = canvas.width;
      pageCanvas.height = pxPageHeight;

      // Initialize the PDF.
      var pdf = new jsPDF('p', 'in', [8.5, 11]);

      for (var page = 0; page < nPages; page++) {
        // Trim the final page to reduce file size.
        if (page === nPages - 1 && pxFullHeight % pxPageHeight !== 0) {
          pageCanvas.height = pxFullHeight % pxPageHeight;
          pageHeight = (pageCanvas.height * innerPageWidth) / pageCanvas.width;
        }

        // Display the page.
        var w = pageCanvas.width;
        var h = pageCanvas.height;
        pageCtx.fillStyle = 'white';
        pageCtx.fillRect(0, 0, w, h);
        pageCtx.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h);

        // Add the page to the PDF.
        if (page > 0) pdf.addPage();
        // debugger;
        var imgData = pageCanvas.toDataURL('image/' + image.type, image.quality);
        pdf.addImage(imgData, image.type, margin[1], margin[0], innerPageWidth, pageHeight);
      }

      // pdf.save();
      let preview_file = blobToFile(pdf.output('blob'), 'Application_form_preview.pdf')
      return preview_file
  }

  

  const submitForm = async () => {
    let request = { ...values };
    let qualifications = [...professionalQualifications];

    // Look for validations
    if (request.full_name?.trim() === "") {
      setRequiredClasses({ ...requiredClasses, full_name: "form-input error" })
      alert("Please enter full name");
      return
    } else if (request?.api_membership_no.trim() === "") {
      setRequiredClasses({ ...requiredClasses, api_membership_no: "form-input error" })
      alert("Please enter api membership number.");
      return
    } else if (request?.date_of_joining.trim() === "") {
      setRequiredClasses({ ...requiredClasses, date_of_joining: "form-input error" })
      alert("Please enter date of joining.");
      return
    } else if (request?.date_of_birth.trim() === "") {
      setRequiredClasses({ ...requiredClasses, date_of_birth: "form-input error" })
      alert("Please enter date of birth.");
      return
    } else if (attachments?.user_profile_img === null) {
      alert("Please upload profile picture.");
      return
    } else if (request?.residence_address.trim() === "") {
      setRequiredClasses({ ...requiredClasses, residence_address: "form-input error" })
      alert("Please enter residence address.");
      return
    } else if (request?.office_address.trim() === "") {
      setRequiredClasses({ ...requiredClasses, office_address: "form-input error" })
      alert("Please enter office address.");
      return
    } else if (!validateEmail(request?.user_email.trim())) {
      setRequiredClasses({ ...requiredClasses, user_email: "form-input error" })
      alert("Please enter a valid email address.");
      return
    } else if (!phonenumber(request?.user_mobile.trim())) {
      setRequiredClasses({ ...requiredClasses, user_mobile: "form-input error" })
      alert("Please enter a valid mobile number");
      return
    } else if (!validateDegree(professionalQualifications)) {
      setRequiredClasses({ ...requiredClasses, qualification: "form-input error" })
      alert("Please enter qualification details");
      return
    }
    // else if (!validateExperience(experienceList)) {
    //   setRequiredClasses({ ...requiredClasses, experience: "form-input error" })
    //   alert("Please enter experience in Medical Profession");
    //   return
    // }
    // else if (request?.nos_of_publications.trim() === "") {
    //   setRequiredClasses({ ...requiredClasses, nos_of_publications: "form-input error" })
    //   alert("Please enter number of publications.");
    //   return
    // }
    // else if (request?.nos_of_chapters_in_books.trim() === "") {
    //   setRequiredClasses({ ...requiredClasses, nos_of_chapters_in_books: "form-input error" })
    //   alert("Please enter number of chapters in book.");
    //   return
    // }
    // else if (request?.editorship_of_national_state_level.trim() === "") {
    //   setRequiredClasses({ ...requiredClasses, editorship_of_national_state_level: "form-input error" })
    //   alert("Please enter required field.");
    //   return
    // }
    else if (request?.nos_of_publications !== "" && publicationAttachmentList[0] === null) {
      alert("Please upload publication attachment.");
      return
    } 
    // else if (request?.research_work.trim() === "") {
    //   setRequiredClasses({ ...requiredClasses, research_work: "form-input error" })
    //   alert("Please enter your research work details.");
    //   return
    // }
    // else if (request?.departmental_research.trim() === "") {
    //   setRequiredClasses({ ...requiredClasses, departmental_research: "form-input error" })
    //   alert("Please enter your departmental research details.");
    //   return
    // }
    else if (!validateProposer(proposerList[0], 1)) {
      setRequiredClasses({ ...requiredClasses, proposer1: "form-input error" })
      alert("Blank fields/duplicte emails are not allowed.");
      return
    } else if (!validateProposer(proposerList[1], 0)) {
      setRequiredClasses({ ...requiredClasses, proposer2: "form-input error" })
      alert("Blank fields/duplicte emails are not allowed.");
      return
    }
    setUploading(true);
    let publication_attachment_arr = [];
    publicationAttachmentList?.map(async (item, index) => {
      if (item !== null) {
        let fileUrl = await fileUpload(item, timestamp)
        let ele = { attachment: fileUrl }
        publication_attachment_arr.push(ele)
      }
    })

    request = { ...request, publication_attachment: publication_attachment_arr }

    let sanction_attachment_arr = [];
    sanctionAttachmentList?.map(async (item, index) => {
      if (item !== null) {
        let fileUrl = await fileUpload(item, timestamp)
        let ele = { attachment: fileUrl }
        sanction_attachment_arr.push(ele)
      }
    })

    request = { ...request, letter_of_sanction: sanction_attachment_arr }

    let abstract_attachment_arr = [];
    abstractAttachmentList?.map(async (item, index) => {
      if (item !== null) {
        let fileUrl = await fileUpload(item, timestamp)
        let ele = { attachment: fileUrl }
        abstract_attachment_arr.push(ele)
      }
    })

    request = { ...request, title_page_abstract: abstract_attachment_arr }


    professionalQualifications?.map(async (item, index) => {
      if (item?.certificate_img !== null) {
        let fileUrl = await fileUpload(item?.certificate_img, timestamp)
        item = { ...item, certificate_img: fileUrl }
        qualifications[index] = item
      } else {
        item = { ...item, certificate_img: "" }
        qualifications[index] = item
      }
    })

    request = { ...request, qual: qualifications }

    let evidences = [...evidenceList];
    evidenceList?.map(async (item, index) => {
      if (item?.social_welfare_evidence !== null) {
        let fileUrl = await fileUpload(item?.social_welfare_evidence, timestamp)
        item = { ...item, social_welfare_evidence: fileUrl }
        evidences[index] = item
      } else {
        item = { ...item, social_welfare_evidence: "" }
        evidences[index] = item
      }
    })

    request = { ...request, social_welfares: evidences }

    let orations = [...orationList];
    orationList?.map(async (item, index) => {
      if (item?.oration_proof !== null) {
        let fileUrl = await fileUpload(item?.oration_proof, timestamp)
        item = { ...item, oration_proof: fileUrl }
        orations[index] = item
      } else {
        item = { ...item, oration_proof: "" }
        orations[index] = item
      }
    })
    request = { ...request, oration_list: orations }

    let awards = [...awardList];
    awardList?.map(async (item, index) => {
      if (item?.award_proof !== null) {
        let fileUrl = await fileUpload(item?.award_proof, timestamp)
        item = { ...item, award_proof: fileUrl }
        awards[index] = item
      } else {
        item = { ...item, award_proof: "" }
        awards[index] = item
      }
    })
    request = { ...request, award_list: awards }

    let contributions = [...contributionList];
    contributionList?.map(async (item, index) => {
      if (item?.cont_api_proof !== null) {
        let fileUrl = await fileUpload(item?.cont_api_proof, timestamp)
        item = { ...item, cont_api_proof: fileUrl }
        contributions[index] = item
      } else {
        item = { ...item, cont_api_proof: "" }
        contributions[index] = item
      }
    })
    request = { ...request, contributions_to_api: contributions, docs_folder: timestamp }

    let cmes = [...cmeList];
    request = { ...request, cmes_to_api: cmes }

    if (attachments?.user_profile_img !== null) {
      let fileUrl = await fileUpload(attachments?.user_profile_img, timestamp)
      request = { ...request, user_profile_img: fileUrl }
    }

    // if (attachments?.publication_attachment !== null) {
    //   let fileUrl = await fileUpload(attachments?.publication_attachment, timestamp)
    //   request = { ...request, publication_attachment: fileUrl }
    // }

    if (attachments?.oration_proof !== null) {
      let fileUrl = await fileUpload(attachments?.oration_proof, timestamp)
      request = { ...request, oration_proof: fileUrl }
    }

    if (attachments?.award_proof !== null) {
      let fileUrl = await fileUpload(attachments?.award_proof, timestamp)
      request = { ...request, award_proof: fileUrl }
    }

    // if (attachments?.letter_of_sanction !== null) {
    //   let fileUrl = await fileUpload(attachments?.letter_of_sanction, timestamp)
    //   request = { ...request, letter_of_sanction: fileUrl }
    // }

    // if (attachments?.title_page_abstract !== null) {
    //   let fileUrl = await fileUpload(attachments?.title_page_abstract, timestamp)
    //   request = { ...request, title_page_abstract: fileUrl }
    // }

    if (attachments?.cont_api_proof !== null) {
      let fileUrl = await fileUpload(attachments?.cont_api_proof, timestamp)
      request = { ...request, cont_api_proof: fileUrl }
    }

    let preview_file = await generatePDF()
    let fileUrl = await fileUpload(preview_file, timestamp)

    request = { ...request, fellowship_member_exp: experienceList, proposer: proposerList, form_preview: fileUrl }
    try {
      await axios
        .post(
          `${site_ip}/createFellowshipDetails`,
          request
        )
        .then((res) => {
          setUploading(false)
          if (res.data.Status === 400) {
            alert(res.data?.Message)
          } else {
            navigate("/thankyou");
          }
        })
        .catch((err) => console.log(err));
    } catch (e) {

    }
  }

  return (
    <div className="form-container">
      {/* <!-------------Slider-section-start---------------> */}
	  
	  <section  style={{ display: "none" }}>
          <div>
            <Row className="no-margin">
              <Col md={12} style={{ textAlign: "center", padding:"100px" ,fontWeight:'bold'}}> <p> Form submission has been closed. </p> </Col>
			</Row>  
		  </div> 
	  </section>  	
		
      <div id="preview" >
        <section>
          <div className="page-logo-div">
            <img src={"/images/top_banner.jpg"} alt='demo' className="mt-2" />
          </div>
          {/* <div className="title">
          <p className="page-heading">Indian College of Physicians</p>
        </div> */}
          <div className="sub-title">
            <Row>
              <Col md={2}>
              </Col>
              <Col md={8}>
                <p className="page-sub-heading">
                  <b>Format for Submission of Bio - Data of The Nominee for Consideration
                    for Award of Fellowship of Indian College of Physicians.</b>
                </p>
                <p className="page-sub-heading">
                  <b>For any queries, please email at api.hdo@gmail.com or call at - (022) 6666 3224.</b>
                </p>
              </Col>
              <Col md={2}>
              </Col>
            </Row>
          </div>
        </section>
		
		
        <section >
          <div>
            <Row className="no-margin">
              <Col md={6}>
                <p style={{ fontSize: "10px", marginBottom: "5px" }}>Please mention NA in case of no information available for any point.</p>
              </Col>
              <Col md={6}>
                <p style={{ fontSize: "10px", marginBottom: "5px", textAlign: "right" }}>For uploading documents maximum size allowed is 2MB.</p>
                <p style={{ fontSize: "10px", marginBottom: "0px", textAlign: "right" }}>Format of file name for uploading documents</p>
                <p style={{ fontSize: "10px", marginBottom: "5px", textAlign: "right" }}>Your name_document name (e.g. Dr_xyz_pg_degree_certificate.png)</p>
              </Col>
            </Row>
            <div className="apply_box">
              <Row className="no-margin">
                <Col md={9} className="border-all">
                  <Row>
                    <Col className="border-all flex">
                      <p>1. Name in Full(Surname First)(in Block Letters): <span className="required">*</span></p>
                      <input type="text" className={requiredClasses?.full_name} name="full_name" value={values?.full_name} onChange={handleChange} ></input>

                    </Col>
                  </Row>
                  <Row>
                    <Col className="border-all flex">
                      <p>2.(a) API Membership Number: <span className="required">*</span></p>
                      <input type="text" className={requiredClasses?.api_membership_no} name="api_membership_no" value={values?.api_membership_no} onChange={handleChange}></input>
                    </Col>
                    <Col className="border-all flex">
                      <p>2.(b) Date of Joining <span className="required">*</span></p>
                      <input type="date" className={requiredClasses?.date_of_joining} name="date_of_joining" value={values?.date_of_joining} onChange={handleChange}></input>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="border-all flex">
                      <p>3. Date of Birth: <span className="required">*</span></p>
                      <input type="date" className={requiredClasses?.date_of_birth} name="date_of_birth" value={values?.date_of_birth} onChange={handleChange} ></input>
                    </Col>
                  </Row>
                </Col>
                <Col md={3} className="border-all p-3">
                  {dp !== null ?
                    <img src={dp} style={{ height: "100px", objectFit: "contain" }} /> : null}
                  <input type={"file"} onChange={(e) => handleAttachment("user_profile_img", e.target.files[0])}></input>
                  <p>Upload your latest photograph <span className="required">*</span></p>
                </Col>
              </Row>
              <Row className="no-margin">
                <Col md={6} className="border-all">
                  <p>Address Residence: <span className="required">*</span></p>
                  <textarea className={requiredClasses?.residence_address} name="residence_address" onChange={handleChange} value={values?.residence_address} />
                </Col>
                <Col md={6} className="border-all">
                  <p>Address Office: <span className="required">*</span></p>
                  <textarea className={requiredClasses?.office_address} name="office_address" onChange={handleChange} value={values?.office_address} />
                </Col>
              </Row>
              <Row className="no-margin">
                <Col md={1} className="border-all">
                  <p>4</p>
                </Col>
                <Col md={11} className="border-all no-padding">
                  <Row className="no-margin">
                    <Col md={4} className="">
                      <p>Telephone:</p>
                      <input type={"text"} className="form-input" name="user_phone" value={values?.user_phone} onChange={handleChange}></input>
                    </Col>
                    <Col md={4} className="border-all">
                      <p>Mobile: <span className="required">*</span></p>
                      <input type={"text"} className={requiredClasses?.user_mobile} name="user_mobile" value={values?.user_mobile} onChange={handleChange}></input>
                    </Col>
                    <Col md={4} >
                      <p>Email: <span className="required">*</span></p>
                      <input type={"text"} className={requiredClasses?.user_email} name="user_email" value={values?.user_email} onChange={handleChange}></input>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="no-margin">
                <Col md={1} className="border-all">
                  <p>5</p>
                </Col>
                <Col md={11} className="border-all no-padding">
                  <Row className="no-margin">
                    <Col md={3} className="">
                      <p>Postgraduate degree in Medicine: <span className="required">*</span></p>
                      <input type={"text"} className={requiredClasses?.qualification} name="degree_name" value={professionalQualifications[0]?.degree_name} onChange={(e) => handleQualification(0, e)}></input>
                    </Col>
                    <Col md={2} className="border-all">
                      <p>Year of passing:<span className="required">*</span></p>
                      <input type={"text"} className={requiredClasses?.qualification} name="year_of_passing" value={professionalQualifications[0]?.year_of_passing} onChange={(e) => handleQualification(0, e)}></input>
                    </Col>
                    <Col md={3} className="border-all">
                      <p>Institute:<span className="required">*</span></p>
                      <input type={"text"} className={requiredClasses?.qualification} name="institute" value={professionalQualifications[0]?.institute} onChange={(e) => handleQualification(0, e)}></input>
                    </Col>
                    <Col md={2} className="border-all">
                      <p>University:<span className="required">*</span></p>
                      <input type={"text"} className={requiredClasses?.qualification} name="university" value={professionalQualifications[0]?.university} onChange={(e) => handleQualification(0, e)}></input>
                    </Col>
                    <Col md={2} className="border-all">
                      <p>Attach Certificates :<span className="required">*</span></p>
                      <input type={"file"} className={requiredClasses?.qualification} onChange={(e) => handlePQAttachment(0, e.target.files[0])}></input>
                    </Col>
                  </Row>
                  <Row className="no-margin border-all">
                    <Col md={3} className="">
                      <p>Other Professional Qualification:</p>
                      <input type={"text"} className="form-input" name="degree_name" value={professionalQualifications[1]?.degree_name} onChange={(e) => handleQualification(1, e)}></input>
                      {professionalQualifications?.length === 2 ? <a href="/" onClick={(e) => { e.preventDefault(); handlePQ("Add", 1) }} className="link-btn">Add More</a> : null}
                    </Col>
                    <Col md={2} className="border-all">
                      <p>Year of passing:</p>
                      <input type={"text"} className="form-input" name="year_of_passing" value={professionalQualifications[1]?.year_of_passing} onChange={(e) => handleQualification(1, e)}></input>
                    </Col>
                    <Col md={3} className="border-all">
                      <p>Institute:</p>
                      <input type={"text"} className="form-input" name="institute" value={professionalQualifications[1]?.institute} onChange={(e) => handleQualification(1, e)}></input>
                    </Col>
                    <Col md={2} className="border-all">
                      <p>University:</p>
                      <input type={"text"} className="form-input" name="university" value={professionalQualifications[1]?.university} onChange={(e) => handleQualification(1, e)}></input>
                    </Col>
                    <Col md={2}>
                      <p>Attach Certificates :</p>
                      <input type={"file"} onChange={(e) => handlePQAttachment(1, e.target.files[0])}></input>
                    </Col>
                  </Row>
                  {professionalQualifications?.map((item, index) => {
                    if (index > 1) {
                      return (
                        <Row className="no-margin border-all" key={index}>
                          <Col md={3} className="">
                            <input type={"text"} className="form-input" name="degree_name" value={professionalQualifications[index]?.degree_name} onChange={(e) => handleQualification(index, e)}></input>
                            {professionalQualifications?.length - 1 === index ? <a href="/" onClick={(e) => { e.preventDefault(); handlePQ("Add", 1) }} className="link-btn">Add More</a> : null}
                            <a href="/" onClick={(e) => { e.preventDefault(); handlePQ("Remove", index) }} className="link-btn" style={{ marginLeft: "10px" }}>Remove</a>
                          </Col>
                          <Col md={2} className="border-all">
                            <input type={"text"} className="form-input" name="year_of_passing" value={professionalQualifications[index]?.year_of_passing} onChange={(e) => handleQualification(index, e)}></input>
                          </Col>
                          <Col md={3} className="border-all">
                            <input type={"text"} className="form-input" name="institute" value={professionalQualifications[index]?.institute} onChange={(e) => handleQualification(index, e)}></input>
                          </Col>
                          <Col md={2} className="border-all">
                            <input type={"text"} className="form-input" name="university" value={professionalQualifications[index]?.university} onChange={(e) => handleQualification(index, e)}></input>
                          </Col>
                          <Col md={2} className="p-3">
                            <input type={"file"} onChange={(e) => handlePQAttachment(index, e.target.files[0])}></input>
                          </Col>
                        </Row>
                      )
                    }
                  })}
                </Col>
              </Row>
              <Row className="no-margin">
                <Col md={1} className="border-all">
                  <p>6</p>
                </Col>
                <Col md={11} className="border-all">
                  <p>Experience in Medical Profession after Postgratuation in Medicine:</p>
                </Col>
              </Row>
              <Row className="no-margin border-all">
                <Col md={4} className="">
                  <p>Name of Hospital/Clinic/Organisation & Location:</p>
                  <input type={"text"} className="form-input" name="hospital_name" value={experienceList[0]?.hospital_name} onChange={(e) => handleExperienceValues(0, e)}></input>
                  {experienceList?.length === 1 ? <a href="/" onClick={(e) => { e.preventDefault(); handleExperience("Add", 1) }} className="link-btn">Add More</a> : null}
                </Col>
                <Col md={2} className="border-all">
                  <p>Number of Beds (if applicable):</p>
                  <input type={"number"} className="form-input" name="number_of_beds" value={experienceList[0]?.number_of_beds} onChange={(e) => handleExperienceValues(0, e)}></input>
                </Col>
                <Col md={2} className="border-all">
                  <p>Post held:</p>
                  <input type={"text"} className="form-input" name="post_held" value={experienceList[0]?.post_held} onChange={(e) => handleExperienceValues(0, e)}></input>
                </Col>
                <Col md={2} className="border-all">
                  <p>Period Served year wise (From):</p>
                  <input type={"number"} className="form-input" name="served_from_year" value={experienceList[0]?.served_from_year} onChange={(e) => handleExperienceValues(0, e)}></input>
                </Col>
                <Col md={2} className="border-all">
                  <p>Period Served year wise (To):</p>
                  <input type={"number"} className="form-input" name="served_to_year" value={experienceList[0]?.served_to_year} onChange={(e) => handleExperienceValues(0, e)}></input>
                </Col>
              </Row>
              {experienceList?.map((item, index) => {
                if (index > 0) {
                  return (
                    <Row className="no-margin border-all" key={index}>
                      <Col md={4} className="">
                        <input type={"text"} className="form-input" name="hospital_name" value={experienceList[index]?.hospital_name} onChange={(e) => handleExperienceValues(index, e)}></input>
                        {experienceList?.length - 1 === index ? <a href="/" onClick={(e) => { e.preventDefault(); handleExperience("Add", 1) }} className="link-btn">Add More</a> : null}
                        <a href="/" onClick={(e) => { e.preventDefault(); handleExperience("Remove", index) }} className="link-btn" style={{ marginLeft: "10px" }}>Remove</a>
                      </Col>
                      <Col md={2} className="border-all">
                        <input type={"number"} className="form-input" name="number_of_beds" value={experienceList[index]?.number_of_beds} onChange={(e) => handleExperienceValues(index, e)}></input>
                      </Col>
                      <Col md={2} className="border-all">
                        <input type={"text"} className="form-input" name="post_held" value={experienceList[index]?.post_held} onChange={(e) => handleExperienceValues(index, e)}></input>
                      </Col>
                      <Col md={2} className="border-all">
                        <input type={"number"} className="form-input" name="served_from_year" value={experienceList[index]?.served_from_year} onChange={(e) => handleExperienceValues(index, e)}></input>
                      </Col>
                      <Col md={2} className="border-all">
                        <input type={"number"} className="form-input" name="served_to_year" value={experienceList[index]?.served_to_year} onChange={(e) => handleExperienceValues(index, e)}></input>
                      </Col>
                    </Row>
                  )
                }
              })}
              <Row className="no-margin">
                <Col md={1} className="border-all">
                  <p>7</p>
                </Col>
                <Col md={11} className="border-all">
                  <p>Publications: List below. (If number of publications in Journals exceeds 8, publications which can qualify as research papers may be listed under Research section 9.)</p>
                </Col>
              </Row>
              <Row className="no-margin">
                <Col md={9} className="border-all">
                  <Row>
                    {/* <Col xs={12}>
                    <p>a) Number of Publications in Indexed National / International Journals</p>
                  </Col>
                  <Col xs={12} className="border-all p-3">
                    <textarea className="" />
                  </Col> */}
                    <Col xs={12} className="border-all flex">
                      <p>a. Number of Publications in Indexed National / International Journals:</p>
                      <input type="text" className="form-input" name="nos_of_publications" value={values?.nos_of_publications} onChange={handleChange}></input>
                    </Col>
                    <Col xs={12} className="border-all flex">
                      <p>b. Number of Chapter in Books / monograms:</p>
                      <input type="text" className="form-input" name="nos_of_chapters_in_books" value={values?.nos_of_chapters_in_books} onChange={handleChange}></input>
                    </Col>
                    <Col xs={12} className="border-all flex">
                      <p>c. Editorship of National level or State level: Book /Monogram/Update Series:</p>
                      <input type="text" className="form-input" name="editorship_of_national_state_level" value={values?.editorship_of_national_state_level} onChange={handleChange}></input>
                    </Col>
                  </Row>
                </Col>
                <Col md={3} className="border-all p-3">
                  {publicationAttachmentList?.map((item, index) => {
                    return (
                      <div key={index}>
                        <div>
                          <input type={"file"} value={""} className={"form-input"} onChange={(e) => managePublicationAttachment(index, e.target.files[0], "file")}></input>
                          {item !== null ? <span style={{ color: "#000000", fontSize: "11px" }}>{item?.name}</span> : null}
                        </div>
                        {publicationAttachmentList?.length - 1 === index ? <a href="/" onClick={(e) => { e.preventDefault(); handlePublicationAttachment("Add", 1) }} className="link-btn">Add More</a> : null}
                        {publicationAttachmentList?.length > 1 ? <a href="/" onClick={(e) => { e.preventDefault(); handlePublicationAttachment("Remove", index) }} className="link-btn" style={{ marginLeft: "10px" }}>Remove</a> : null}
                      </div>
                    )
                  })}
                </Col>
              </Row>
              <Row className="no-margin">
                <Col md={1} className="border-all">
                  <p>8</p>
                </Col>
                <Col md={11} className="border-all">
                  <p>Honours And Awards</p>
                </Col>
              </Row>
              <Row className="no-margin">
                <Col md={12} className="border-all">
                  <p>(a) Oration in National / State Association Meeting</p>
                </Col>
              </Row>
              <Row className="no-margin border-all">
                <Col md={3} className="">
                  <p>Title of Oration:</p>
                  <input type={"text"} className="form-input" name="oration_title" value={orationList[0]?.oration_title} onChange={(e) => handleOrationValues(0, e)}></input>
                  {orationList?.length === 1 ? <a href="/" onClick={(e) => { e.preventDefault(); handleOration("Add", 1) }} className="link-btn">Add More</a> : null}
                </Col>
                <Col md={3} className="border-all">
                  <p>Organisation:</p>
                  <input type={"text"} className="form-input" name="oration_org" value={orationList[0]?.oration_org} onChange={(e) => handleOrationValues(0, e)}></input>
                </Col>
                <Col md={3} className="border-all">
                  <p>Year:</p>
                  <input type={"text"} className="form-input" name="oration_year" value={orationList[0]?.oration_year} onChange={(e) => handleOrationValues(0, e)}></input>
                </Col>
                <Col md={3}>
                  <p>Attach Proof</p>
                  <input type={"file"} name="oration_proof" onChange={(e) => handleOrationValues(0, e.target.files[0], "file")}></input>
                </Col>
              </Row>
              {orationList?.map((item, index) => {
                if (index > 0) {
                  return (
                    <Row className="no-margin border-all">
                      <Col md={3} className="">
                        <input type={"text"} className="form-input" name="oration_title" value={orationList[index]?.oration_title} onChange={(e) => handleOrationValues(index, e)}></input>
                        {orationList?.length - 1 === index ? <a href="/" onClick={(e) => { e.preventDefault(); handleOration("Add", 1) }} className="link-btn">Add More</a> : null}
                        <a href="/" onClick={(e) => { e.preventDefault(); handleOration("Remove", index) }} className="link-btn" style={{ marginLeft: "10px" }}>Remove</a>
                      </Col>
                      <Col md={3} className="border-all">
                        <input type={"text"} className="form-input" name="oration_org" value={orationList[index]?.oration_org} onChange={(e) => handleOrationValues(index, e)}></input>
                      </Col>
                      <Col md={3} className="border-all">
                        <input type={"text"} className="form-input" name="oration_year" value={orationList[index]?.oration_year} onChange={(e) => handleOrationValues(index, e)}></input>
                      </Col>
                      <Col md={3} className="p-3">
                        <input type={"file"} name="oration_proof" onChange={(e) => handleOrationValues(index, e.target.files[0], "file")}></input>
                      </Col>
                    </Row>
                  )
                }
              })}
              <Row className="no-margin">
                <Col md={12} className="border-all">
                  <p>8. (b) Award National / International / or State level</p>
                </Col>
              </Row>
              <Row className="no-margin">
                <Col md={3} className="border-all">
                  <p>Title of Award:</p>
                  <input type={"text"} className="form-input" name="award_title" value={awardList[0]?.award_title} onChange={(e) => handleAwardValues(0, e)}></input>
                  {awardList?.length === 1 ? <a href="/" onClick={(e) => { e.preventDefault(); handleAward("Add", 1) }} className="link-btn">Add More</a> : null}
                </Col>
                <Col md={3} className="border-all">
                  <p>Organisation:</p>
                  <input type={"text"} className="form-input" name="award_org" value={awardList[0]?.award_org} onChange={(e) => handleAwardValues(0, e)}></input>
                </Col>
                <Col md={3} className="border-all">
                  <p>Year:</p>
                  <input type={"text"} className="form-input" name="award_year" value={awardList[0]?.award_year} onChange={(e) => handleAwardValues(0, e)}></input>
                </Col>
                <Col md={3}>
                  <p>Attach Proof</p>
                  <input type={"file"} name="award_proof" onChange={(e) => handleAwardValues(0, e.target.files[0], "file")}></input>
                </Col>
              </Row>
              {awardList?.map((item, index) => {
                if (index > 0) {
                  return (
                    <Row className="no-margin border-all" key={index}>
                      <Col md={3} className="">
                        <input type={"text"} className="form-input" name="award_title" value={awardList[index]?.award_title} onChange={(e) => handleAwardValues(index, e)}></input>
                        {awardList?.length - 1 === index ? <a href="/" onClick={(e) => { e.preventDefault(); handleAward("Add", 1) }} className="link-btn">Add More</a> : null}
                        <a href="/" onClick={(e) => { e.preventDefault(); handleAward("Remove", index) }} className="link-btn" style={{ marginLeft: "10px" }}>Remove</a>
                      </Col>
                      <Col md={3} className="border-all">
                        <input type={"text"} className="form-input" name="award_org" value={awardList[index]?.award_org} onChange={(e) => handleAwardValues(index, e)}></input>
                      </Col>
                      <Col md={3} className="border-all">
                        <input type={"text"} className="form-input" name="award_year" value={awardList[index]?.award_year} onChange={(e) => handleAwardValues(index, e)}></input>
                      </Col>
                      <Col md={3} className="p-3">
                        <input type={"file"} name="award_proof" onChange={(e) => handleAwardValues(index, e.target.files[0], "file")}></input>
                      </Col>
                    </Row>
                  )
                }
              })}
              <Row className="no-margin">
                <Col md={1} className="border-all">
                  <p>9</p>
                </Col>
                <Col md={11} className="border-all">
                  <p>Research work (list below)</p>
                </Col>
              </Row>
              <Row className="no-margin">
                <Col md={9} className="border-all">
                  <p>(a) Research sanctioned & funded by Research Agency</p>
                  <textarea className="form-input" name="research_work" value={values?.research_work} onChange={handleChange} />
                </Col>
                <Col md={3} className="border-all">
                  <p>Attach Letter of sanction </p>
                  {sanctionAttachmentList?.map((item, index) => {
                    return (
                      <div key={index}>
                        <div>
                          <input type={"file"} value={""} className={"form-input"} onChange={(e) => manageSanctionAttachment(index, e.target.files[0], "file")}></input>
                          {item !== null ? <span style={{ color: "#000000", fontSize: "11px" }}>{item?.name}</span> : null}
                        </div>
                        {sanctionAttachmentList?.length - 1 === index ? <a href="/" onClick={(e) => { e.preventDefault(); handleSanctionAttachment("Add", 1) }} className="link-btn">Add More</a> : null}
                        {sanctionAttachmentList?.length > 1 ? <a href="/" onClick={(e) => { e.preventDefault(); handleSanctionAttachment("Remove", index) }} className="link-btn" style={{ marginLeft: "10px" }}>Remove</a> : null}
                      </div>
                    )
                  })}
                  {/* <input className={requiredClasses?.letter_of_sanction} type={"file"} name="letter_of_sanction" onChange={(e) => handleAttachment("letter_of_sanction", e.target.files[0])}></input> */}
                </Col>
              </Row>
              <Row className="no-margin">
                <Col md={9} className="border-all">
                  <p style={{ whiteSpace: "pre-wrap" }}>(b) Departmental Research. (To qualify, the findings should be published in National/International Journal) Do not include papers already listed under Publications</p>
                  <textarea className="form-input" name="departmental_research" value={values?.departmental_research} onChange={handleChange} />
                </Col>
                <Col md={3} className="border-all">
                  <p>Attach title page / Abstract </p>
                  {abstractAttachmentList?.map((item, index) => {
                    return (
                      <div key={index}>
                        <div>
                          <input type={"file"} value={""} className={"form-input"} onChange={(e) => manageAbstractAttachment(index, e.target.files[0], "file")}></input>
                          {item !== null ? <span style={{ color: "#000000", fontSize: "11px" }}>{item?.name}</span> : null}
                        </div>
                        {abstractAttachmentList?.length - 1 === index ? <a href="/" onClick={(e) => { e.preventDefault(); handleAbstractAttachment("Add", 1) }} className="link-btn">Add More</a> : null}
                        {abstractAttachmentList?.length > 1 ? <a href="/" onClick={(e) => { e.preventDefault(); handleAbstractAttachment("Remove", index) }} className="link-btn" style={{ marginLeft: "10px" }}>Remove</a> : null}
                      </div>
                    )
                  })}
                </Col>
              </Row>
              <Row className="no-margin">
                <Col md={1} className="border-all">
                  <p>10</p>
                </Col>
                <Col md={11} className="border-all">
                  <p>Contribution to API (list below and attach proof)</p>
                </Col>
              </Row>
              <Row className="no-margin border-all">
                <Col md={3} className="">
                  <p>Post held in Organisation/Meeting:</p>
                  <input type={"text"} className="form-input" name="cont_api_post_held" value={contributionList[0]?.cont_api_post_held} onChange={(e) => handleContributionValues(0, e)}></input>
                  {contributionList?.length === 1 ? <a href="/" onClick={(e) => { e.preventDefault(); handleContribution("Add", 1) }} className="link-btn">Add More</a> : null}
                </Col>
                <Col md={3} className="border-all">
                  <p>Name of Organisation/Meeting/CME:</p>
                  <input type={"text"} className="form-input" name="cont_api_organization" value={contributionList[0]?.cont_api_organization} onChange={(e) => handleContributionValues(0, e)}></input>
                </Col>
                <Col md={2} className="border-all">
                  <p style={{ whiteSpace: "pre-wrap" }}>National/Zonal/Under API/ICP State level:</p>
                  <input type={"text"} className="form-input" name="cont_api_level" value={contributionList[0]?.cont_api_level} onChange={(e) => handleContributionValues(0, e)}></input>
                </Col>
                <Col md={2} className="border-all">
                  <p>Year:</p>
                  <input type={"text"} className="form-input" name="cont_api_year" value={contributionList[0]?.cont_api_year} onChange={(e) => handleContributionValues(0, e)}></input>
                </Col>
                <Col md={2} className="border-all">
                  <p>Attach Proof</p>
                  <input type={"file"} name="cont_api_proof" onChange={(e) => handleAwardValues(0, e.target.files[0], "file")}></input>
                </Col>
              </Row>
              {contributionList?.map((item, index) => {
                if (index > 0) {
                  return (
                    <Row className="no-margin border-all" key={index}>
                      <Col md={3} className="">
                        <input type={"text"} className="form-input" name="cont_api_post_held" value={contributionList[index]?.cont_api_post_held} onChange={(e) => handleContributionValues(index, e)}></input>
                        {contributionList?.length - 1 === index ? <a href="/" onClick={(e) => { e.preventDefault(); handleContribution("Add", 1) }} className="link-btn">Add More</a> : null}
                        <a href="/" onClick={(e) => { e.preventDefault(); handleContribution("Remove", index) }} className="link-btn" style={{ marginLeft: "10px" }}>Remove</a>
                      </Col>
                      <Col md={3} className="border-all">
                        <input type={"text"} className="form-input" name="cont_api_organization" value={contributionList[index]?.cont_api_organization} onChange={(e) => handleContributionValues(index, e)}></input>
                      </Col>
                      <Col md={2} className="border-all">
                        <input type={"text"} className="form-input" name="cont_api_level" value={contributionList[index]?.cont_api_level} onChange={(e) => handleContributionValues(index, e)}></input>
                      </Col>
                      <Col md={2} className="border-all">
                        <input type={"text"} className="form-input" name="cont_api_year" value={contributionList[index]?.cont_api_year} onChange={(e) => handleContributionValues(index, e)}></input>
                      </Col>
                      <Col md={2} className="border-all p-3">
                        <input type={"file"} name="cont_api_proof" onChange={(e) => handleContributionValues(index, e.target.files[0], "file")}></input>
                      </Col>
                    </Row>
                  )
                }
              })}
              < Row className="no-margin">
                <Col md={1} className="border-all">
                  <p>11</p>
                </Col>
                <Col md={11} className="border-all">
                  <p>Participation in CME or Scientific Sessions of API or ICP as Faculty:</p>
                </Col>
              </Row>
              <Row className="no-margin border-all">
                <Col md={3} className="">
                  <p>Speaker/Chairperson/Other:</p>
                  <input type={"text"} className="form-input" name="cme_participation_speaker" value={cmeList[0]?.cme_participation_speaker} onChange={(e) => handleCmeValues(0, e)}></input>
                  {cmeList?.length === 1 ? <a href="/" onClick={(e) => { e.preventDefault(); handleCme("Add", 1) }} className="link-btn">Add More</a> : null}
                </Col>
                <Col md={3} className="border-all">
                  <p>Title of Talk / Session:</p>
                  <input type={"text"} className="form-input" name="cme_title_talk" value={cmeList[0]?.cme_title_talk} onChange={(e) => handleCmeValues(0, e)}></input>
                </Col>
                <Col md={3} className="border-all">
                  <p>Name of meeting:</p>
                  <input type={"text"} className="form-input" name="cme_meeting_name" value={cmeList[0]?.cme_meeting_name} onChange={(e) => handleCmeValues(0, e)}></input>
                </Col>
                <Col md={3} className="border-all">
                  <p>Year:</p>
                  <input type={"text"} className="form-input" name="cme_year" value={cmeList[0]?.cme_year} onChange={(e) => handleCmeValues(0, e)}></input>
                </Col>
              </Row>
              {cmeList?.map((item, index) => {
                if (index > 0) {
                  return (
                    <Row className="no-margin border-all" key={index}>
                      <Col md={3} className="">
                        <input type={"text"} className="form-input" name="cme_participation_speaker" value={cmeList[index]?.cme_participation_speaker} onChange={(e) => handleCmeValues(index, e)}></input>
                        {cmeList?.length - 1 === index ? <a href="/" onClick={(e) => { e.preventDefault(); handleCme("Add", 1) }} className="link-btn">Add More</a> : null}
                        <a href="/" onClick={(e) => { e.preventDefault(); handleCme("Remove", index) }} className="link-btn" style={{ marginLeft: "10px" }}>Remove</a>
                      </Col>
                      <Col md={3} className="border-all">
                        <input type={"text"} className="form-input" name="cme_title_talk" value={cmeList[index]?.cme_title_talk} onChange={(e) => handleCmeValues(index, e)}></input>
                      </Col>
                      <Col md={3} className="border-all">
                        <input type={"text"} className="form-input" name="cme_meeting_name" value={cmeList[index]?.cme_meeting_name} onChange={(e) => handleCmeValues(index, e)}></input>
                      </Col>
                      <Col md={3} className="border-all">
                        <input type={"text"} className="form-input" name="cme_year" value={cmeList[index]?.cme_year} onChange={(e) => handleCmeValues(index, e)}></input>
                      </Col>
                    </Row>
                  )
                }
              })}
              <Row className="no-margin">
                <Col md={1} className="border-all">
                  <p>12</p>
                </Col>
                <Col md={11} className="border-all">
                  <p>Social welfare / Community service. (Include under the headings given below, with documentry evidence)</p>
                  <p>(a) Emergency services during National calamities (Quakes/Floods/Cyclones,etc)</p>
                  <p>(b) Public education Programme (Radio), TV talk/writing in news papers.</p>
                  <p>(c) Service in Rural Areas</p>
                </Col>
              </Row>
              <Row className="no-margin border-all">
                <Col md={6} className="">
                  <p>Service:</p>
                  <textarea className="" name="social_welfare_service" value={evidenceList[0]?.social_welfare_service} onChange={(e) => handleEvidenceValues(0, e, "text")} />
                  {evidenceList?.length === 1 ? <a href="/" onClick={(e) => { e.preventDefault(); handleEvidence("Add", 1) }} className="link-btn">Add More</a> : null}
                </Col>
                <Col md={6} className="border-all">
                  <p>Evidence:</p>
                  <input type={"file"} onChange={(e) => handleEvidenceValues(0, e.target.files[0], "file")}></input>
                </Col>
              </Row>
              {evidenceList?.map((item, index) => {
                if (index > 0) {
                  return (
                    <Row className="no-margin border-all" key={index}>
                      <Col md={6} className="">
                        <p>Service:</p>
                        <textarea className="" name="social_welfare_service" value={evidenceList[index]?.social_welfare_service} onChange={(e) => handleEvidenceValues(index, e)} />
                        {evidenceList?.length - 1 === index ? <a href="/" onClick={(e) => { e.preventDefault(); handleEvidence("Add", 1) }} className="link-btn">Add More</a> : null}
                        <a href="/" onClick={(e) => { e.preventDefault(); handleEvidence("Remove", index) }} className="link-btn" style={{ marginLeft: "10px" }}>Remove</a>
                      </Col>
                      <Col md={6} className="border-all">
                        <p>Evidence:</p>
                        <input type={"file"} onChange={(e) => handleEvidenceValues(index, e.target.files[0], "file")}></input>
                      </Col>
                    </Row>
                  )
                }
              })}
            </div>
          </div>
        </section >
        <section>
          <div className="end">
            <h5><b>Indian College of Physicians Citation</b></h5>
          </div>
          <div>
            <p>Please share the details of two proposers for recommendation:</p>
          </div>
          <div className="apply_box">
            <Row className="no-margin border-all">
              <Col md={6}>
                <Row>
                  <Col xs={12} className="">
                    <p>Proposer 1:</p>
                  </Col>
                  <Col xs={12} className="border-all flex">
                    <p>Name:<span className="required">*</span></p>
                    <input type="text" className={requiredClasses?.proposer1} name="proposer_name" value={proposerList[0]?.proposer_name} onChange={(e) => handleProposerValues(0, e)}></input>
                  </Col>
                  <Col xs={12} className="border-all flex">
                    <p>Fellowship No.:<span className="required">*</span></p>
                    <input type="text" className={requiredClasses?.proposer1} name="membership_no" value={proposerList[0]?.membership_no} onChange={(e) => handleProposerValues(0, e)}></input>
                  </Col>
                  <Col xs={12} className="border-all flex">
                    <p>Email:<span className="required">*</span></p>
                    <input type="text" className={requiredClasses?.proposer1} name="proposer_email" value={proposerList[0]?.proposer_email} onChange={(e) => handleProposerValues(0, e)}></input>
                  </Col>
                  <Col xs={12} className="border-all flex">
                    <p>Mobile No.:<span className="required">*</span></p>
                    <input type="text" className={requiredClasses?.proposer1} name="proposer_phone" value={proposerList[0]?.proposer_phone} onChange={(e) => handleProposerValues(0, e)}></input>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col xs={12} className="border-all">
                    <p>Proposer 2:</p>
                  </Col>
                  <Col xs={12} className="border-all flex">
                    <p>Name:<span className="required">*</span></p>
                    <input type="text" className={requiredClasses?.proposer2} name="proposer_name" value={proposerList[1]?.proposer_name} onChange={(e) => handleProposerValues(1, e)}></input>
                  </Col>
                  <Col xs={12} className="border-all flex">
                    <p>Fellowship No.:<span className="required">*</span></p>
                    <input type="text" className={requiredClasses?.proposer2} name="membership_no" value={proposerList[1]?.membership_no} onChange={(e) => handleProposerValues(1, e)}></input>
                  </Col>
                  <Col xs={12} className="border-all flex">
                    <p>Email:<span className="required">*</span></p>
                    <input type="text" className={requiredClasses?.proposer2} name="proposer_email" value={proposerList[1]?.proposer_email} onChange={(e) => handleProposerValues(1, e)}></input>
                  </Col>
                  <Col xs={12} className="border-all flex">
                    <p>Mobile No.:<span className="required">*</span></p>
                    <input type="text" className={requiredClasses?.proposer2} name="proposer_phone" value={proposerList[1]?.proposer_phone} onChange={(e) => handleProposerValues(1, e)}></input>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div>
            <p><b>Note: </b>Once you submit the form - emails will be sent to both the proposer for their acceptance or rejection for recommendation.</p>
          </div>
        </section>
      </div>
      <Button onClick={() => previewForm()} style={{ marginRight: "15px" }}>Preview</Button>
      <Button onClick={() => submitForm()}>Submit</Button>
      <Modal show={uploading}>
        <Modal.Body style={{ textAlign: 'center' }}>Please wait, while we are uploading.</Modal.Body>
      </Modal>
  
	 </div >
  );
};

export default Home;
