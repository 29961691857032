import React, { useEffect } from 'react';
import Aux from '../hoc/_Aux';
const Logout = (props) => {
  useEffect(() => {
    localStorage.removeItem('userdata');
    localStorage.removeItem('admin_token');
    window.sessionStorage.clear();
    window.sessionStorage.clear();
    // navigate.replace('');
    // this.navigate('/auth/signin');
    window.location.href = '/admin';
    // eslint-disable-next-line
  }, []);
  return <Aux>Logging you out...</Aux>;
};
export default Logout;
